.new-task-container {
    display: grid;
    grid-template-columns: 7fr 4fr;
    gap: 40px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
}

.modal-header .header-options {
    display: flex;
    gap: 20px;
}

.modal-header .d-flex {
    gap: 5px;
}

.form-content .button-actions {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.modal-content .paragraph-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.modal-content .linked-issues {
    margin-top: 30px;
}

.modal-content .activity-content {
    margin-top: 30px;
    margin-bottom: 20px;
}

.modal-content .activity-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-content .activity-tabs .show {
    display: flex;
    gap: 20px;
}