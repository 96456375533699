.linkedIssues-container {
    display: flex;
    justify-content: space-between;
}

.linkedIssues-container .issue-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.linkedIssues-container .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
}