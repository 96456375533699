.sunny_timesheet-modal .input_container {
    display: flex;
    flex-direction: column;
 }

.sunny_timesheet-modal  label {
    color: orange;
}

.sunny_timesheet-modal .d-flex-between {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
  