.new-task-container .backlog-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.new-task-container .backlog-details .collapse-details .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.new-task-container .backlog-details .collapse-details .three-cols-grid {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    gap: 5px;
    align-items: center;
}

.new-task-container .linked-issues .d-flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}