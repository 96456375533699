.userComment-container {
    display: flex;
    gap: 10px;
}

.userComment-container .bottom-text {
    display: flex;
    gap: 5px;
    margin-top: 5px;
}

.userComment-container .userComment-right-section {
    width: 100%
}